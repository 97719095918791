.modal {
    display: flex;
    align-items: center;
    justify-content: center;

    padding-top: 1em;
    width: 100%;
    height: 100vh;
    margin-bottom: 5%;

    &:focus {
        outline: none;
    }
}

.hr {
    color: #ADB8D0;
    opacity: 0.3;
    width: 95%;
}

.paper {
    align-items: center;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 6px 12px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    max-height: 90vh !important;
    height: auto;
    justify-content: flex-start;
    outline: none;
    padding: 2em 2em 1.5em 2em;
    position: relative;
    text-align: center;
    max-width: 80vw !important;
    width: auto !important;
}

.title {
    display: flex;
    justify-content: center;
    color: #006E54;
    font-weight: 200;
    font-size: 2rem !important;
    margin-bottom: 1.3rem;
}

.closeButton {
    cursor: pointer;
    font-size: 1em;
    position: absolute;
    right: 2em;
    top: 1em;
}