.root {
    padding-top: 2rem;
    width: 100%;
    cursor: pointer;
}

.nested {
    display: flex;
    margin-left: 1.5em !important;
    max-width: 270px;
    cursor: pointer;
}

.secondaryNested {
    display: flex;
    margin-left: 3em !important;
}

.iconsDrawer {
    width: 1.6rem;
    font-size: 1.6rem;
    color: white;
}

.itemList {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
    padding-bottom: 1em !important;
}

.link {
    text-decoration: none;
    cursor: pointer;
}

.textColor {
    color: #ffffff;
    flex-grow: 1;
    font-family: Roboto, sans-serif;
    font-size: 0.85rem;
}

.textColorSelect {
    color: #00B187;
}