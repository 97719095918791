@import "../../styles/sassUtils/indexUtils.scss";

.imageContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .imageWrapper {
        overflow: hidden;
        width: 95%;
        height: 95%;
        border-radius: 10px;
        border: 1px solid #EFEFEF;
        box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.3);

        .selfie {
            cursor: pointer;
            height: 100%;
            max-height: 33vh;
            width: 100%;
            object-fit: contain;
        }
    }

}

.dataContainer {
    text-align: left;

    .title {
        flex: 0;
        width: 100%;
        font-weight: 600;
        font-size: 1.3rem;
        text-align: left;
        color: #006E54;
        border-bottom: 1px solid #E6E6E6;
        padding-bottom: 5px;
        margin-bottom: 5px;
    }

    .personalData {
        width: 100%;

        td {
            padding: 0.3rem;
        }

        .label {
            font-weight: 600;
            width: max-content;
        }
    }
}

.cardContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    .card {
        align-items: center;
        background-color: #F3F4F7;
        border-radius: 10px;
        justify-content: center;
        padding: 2em;
        text-align: center;
        flex: 1;
        border-right: 10px solid #006E54;

        .card-label {
            margin: 5px;
            width: 100%;
            font-size: 1.2rem;
            font-weight: 400;
        }

        .card-value {
            margin: 5px;
            color: #006E54;
            width: 100%;
            font-size: 1.5rem;
            font-weight: 600;
        }
    }

}

.mapContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .title {
        flex: 0;
        width: 100%;
        font-weight: 600;
        font-size: 1.3rem;
        text-align: left;
        color: #006E54;
        border-bottom: 1px solid #E6E6E6;
        padding-bottom: 5px;
        margin-bottom: 5px;
    }

    .map {
        flex: 1;
        width: 100%;
        height: 100%;

        .geoLocation {
            overflow: hidden;
            width: 100%;
            height: 100%;
            border: 1px solid rgb(179, 179, 179);
            color: rgb(179, 179, 179);
            border-radius: 10px;
        }
    }

}

.switchButton {
    align-items: center;
    // background-color: $blue;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1em;
    height: 40px;
    justify-content: center;
    margin: -0.2em 1em 1em 0;
    width: 300px;
}

.geoLocation {
    align-items: center;
    display: flex;
    border: 1px solid rgb(179, 179, 179);
    color: rgb(179, 179, 179);
    height: 300px;
    justify-content: center;
    width: 400px;
}